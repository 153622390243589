import React, { useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import { IoCloudUploadOutline } from "react-icons/io5";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { toast } from "react-toastify";

import CircleNumber from "../components/CircleNumber";
import FileTable from "../components/FileTable";
import axios from "axios";

dayjs.extend(advancedFormat);

const DashboardPage = ({
  files,
  fetchUserFiles,
  handleFileDelete,
  handleFileUpload,
  handleFileDownload,
}) => {
  useEffect(() => {
    fetchUserFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filenames = [
    { filename: "AUR", tablename: "AUR_View" },
    { filename: "CRM", tablename: "CRM_Data" },
    { filename: "AppsFlyer", tablename: "AppsFlyerRL" },
    { filename: "Earnings", tablename: "earningsRL" },
    { filename: "Subscription", tablename: "subscriptionRL" },
    { filename: "Reviews", tablename: "reviewsRL" },
  ];
  const [downloadLoading, setDownloadLoading] = useState({});

  // Define download button click handlers
  const handleDownloadButton = async (tablename) => {
    try {
      setDownloadLoading((prevLoading) => ({
        ...prevLoading,
        [tablename]: true, // Set loading to true for the specific table
      }));
      let data = JSON.stringify([
        {
          tablename: tablename,
        },
      ]);

      let config = {
        method: "post",
        responseType: "arraybuffer", // Set the responseType to 'arraybuffer' for binary data
        url: `${process.env.REACT_APP_API_URL}/export-data`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      // Create a Blob from the binary data
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${tablename}_File.csv`;

      // Append the link to the document and trigger the click event
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);
      toast.success("File Downloaded Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Download Failed");
    } finally {
      setDownloadLoading((prevLoading) => ({
        ...prevLoading,
        [tablename]: false, // Set loading to false for the specific table
      }));
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    await handleFileUpload(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const sortedFiles = files.sort((a, b) => {
    return b.id - a.id;
  });

  const steps = [
    {
      id: 1,
      step: "Upload file from desktop or simply drag and drop",
    },
    {
      id: 2,
      step: "Check the status of your file upload (Estimated 10 minutes) Once successful status: your few CRM has been updated in Solariym and after that it will take 30 min to reflect the changes on the dashboard",
    },
    {
      id: 3,
      step: "Delete or Download previous files at a click of a button(If you delete a file after it has been processed the data won't be removed from the dashboard",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          marginTop: {
            xs: "20px",
            md: "30px",
          },
        }}
        textAlign="center"
        color="primary"
      >
        Welcome {localStorage.getItem("name")}!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          marginTop: {
            xs: "20px",
          },
          columnGap: 5,
        }}
      >
        <Box
          maxWidth={600}
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginBottom: "20px",
            }}
            color="primary"
          >
            Deeper insights <br />
            in less time
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ marginBottom: "20px" }}
          >
            Solariym Connect is a Web Application which streamlines CRM file
            management for Sonova's first unified reporting database – Solariym.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            color="primary"
            sx={{ marginBottom: "20px", fontSize: "18px" }}
          >
            Get started in 3 simple steps:
          </Typography>
          <Box>
            {steps.map((step) => (
              <Box
                key={step.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  gap: "10px",
                }}
              >
                <CircleNumber number={step.id} />
                <Typography variant="body1">{step.step}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <a
              href={`/CRM_template_NEW_${localStorage.getItem("name")}.xlsx`}
              download={true}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <BsDownload size={22} color="#1976d2" />
                <Typography
                  variant="body1"
                  gutterBottom
                  color="primary"
                  sx={{ fontSize: "18px" }}
                >
                  Click to Download a CRM template
                </Typography>
              </Box>
            </a>
          </Box>

          {/* Add download buttons and explanation text */}
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              overflow: "auto",
              marginTop: 4,
              marginBottom: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "10px" }}
              color="primary"
            >
              Download Raw Data Files
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {filenames.map((file) => (
                <Box
                  key={file.tablename}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">
                    Download {file.filename} Data File:
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownloadButton(file.tablename)}
                    disabled={downloadLoading[file.tablename]} // Disable the button while loading
                  >
                    {downloadLoading[file.tablename] ? (
                      <>
                        <CircularProgress size={24} /> Downloading...
                      </>
                    ) : (
                      "Download"
                    )}
                  </Button>
                </Box>
              ))}
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Grid container spacing={2} marginBlock="10px">
            <Grid
              item
              xs={12}
              sx={{
                marginInline: {
                  xs: "10px",
                  md: "0px",
                },
              }}
            >
              <CircleNumber number="1" />
              <Paper
                elevation={3}
                sx={{
                  padding: "40px",
                  height: "150px",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    border: "1px dashed #1976d2",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <IoCloudUploadOutline size={70} color="#1976d2" />
                  <Typography variant="body1">
                    Click to upload or Drag and Drop File
                  </Typography>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      e.preventDefault();
                      handleFileUpload(e.target.files);
                    }}
                    multiple
                    accept=".xls, .xlsx"
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  padding: "20px",
                  overflow: "auto",
                  marginInline: {
                    xs: "10px",
                    md: "0px",
                  },
                }}
              >
                {sortedFiles && sortedFiles.length > 0 ? (
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1.7fr 1fr 1.38fr 1.45fr",
                        marginBottom: "10px",
                      }}
                    >
                      <Box></Box>
                      <Box></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircleNumber number="2" />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircleNumber number="3" />
                      </Box>
                    </Box>
                    <FileTable
                      files={sortedFiles}
                      handleFileDelete={handleFileDelete}
                      handleFileDownload={handleFileDownload}
                    />
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    style={{ marginTop: "10px", textAlign: "center" }}
                  >
                    No files uploaded yet.
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default DashboardPage;
